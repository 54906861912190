// GENERATED FILE - DO NOT MODIFY
import { MaterialRequisitionItemModel } from "api/models/company/material-requisition/material-requisition-item-model";
import { MaterialRequisitionModel } from "api/models/company/material-requisition/material-requisition-model";
import { MaterialRequisitionProjectResourceModel } from "api/models/company/material-requisition/material-requisition-project-resource-model";
import { MaterialRequisitionSummaryModel } from "api/models/company/material-requisition/material-requisition-summary-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class DispatchProjectMaterialRequisitionProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetMaterialRequisitionsForDispatchProject(projectDispatchCode: string | null, activeOnly: boolean | undefined, forProjectEquipment: boolean | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<MaterialRequisitionSummaryModel[] | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/materialrequisitions",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode },
            { activeOnly: (activeOnly === null || activeOnly === undefined) ? undefined : activeOnly.toString(), forProjectEquipment: (forProjectEquipment === null || forProjectEquipment === undefined) ? undefined : forProjectEquipment.toString(), filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: MaterialRequisitionSummaryModel[] | null = await this.get<MaterialRequisitionSummaryModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.MaterialRequisition.MaterialRequisitionSummaryModel");
        return data;
    }

    public async SaveMaterialRequisitionHeaderForDispatchProject(projectDispatchCode: string | null, requisitionHeader: MaterialRequisitionModel | null, forProjectEquipment: boolean | undefined, requestConfig?: HttpClientRequestConfig): Promise<MaterialRequisitionSummaryModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/materialrequisitions",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode },
            { forProjectEquipment: (forProjectEquipment === null || forProjectEquipment === undefined) ? undefined : forProjectEquipment.toString() }
        );

        const data: MaterialRequisitionSummaryModel | null = await this.post<MaterialRequisitionModel | null, MaterialRequisitionSummaryModel | null>(uri, requisitionHeader, requestConfig);
        this.transformModels(data, "Company.MaterialRequisition.MaterialRequisitionSummaryModel");
        return data;
    }

    public async SaveMaterialRequisitionDetailForDispatchProject(projectDispatchCode: string | null, requisitionId: string | null, lineId: number, items: Array<MaterialRequisitionItemModel | null> | null, requestConfig?: HttpClientRequestConfig): Promise<MaterialRequisitionItemModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/materialrequisitions/{requisitionId}/{lineId}",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode, requisitionId: (requisitionId === null || requisitionId === undefined) ? undefined : requisitionId, lineId: (lineId === null || lineId === undefined) ? undefined : lineId.toString() },
            null
        );

        const data: MaterialRequisitionItemModel | null = await this.post<Array<MaterialRequisitionItemModel | null> | null, MaterialRequisitionItemModel | null>(uri, items, requestConfig);
        this.transformModels(data, "Company.MaterialRequisition.MaterialRequisitionItemModel");
        return data;
    }

    public async GetMaterialRequisitionProjectResources(projectDispatchCode: string | null, dispatchTemplateId: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<MaterialRequisitionProjectResourceModel[] | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/materialrequisitions/resources",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode },
            { dispatchTemplateId: (dispatchTemplateId === null || dispatchTemplateId === undefined) ? undefined : dispatchTemplateId, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: MaterialRequisitionProjectResourceModel[] | null = await this.get<MaterialRequisitionProjectResourceModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.MaterialRequisition.MaterialRequisitionProjectResourceModel");
        return data;
    }

    public async GetMaterialRequisitionProjectResourceItems(projectDispatchCode: string | null, resourceNumber: string | null, dispatchTemplateId: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<MaterialRequisitionItemModel[] | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/materialrequisitions/{resourceNumber}/items",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode, resourceNumber: (resourceNumber === null || resourceNumber === undefined) ? undefined : resourceNumber },
            { dispatchTemplateId: (dispatchTemplateId === null || dispatchTemplateId === undefined) ? undefined : dispatchTemplateId, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: MaterialRequisitionItemModel[] | null = await this.get<MaterialRequisitionItemModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.MaterialRequisition.MaterialRequisitionItemModel");
        return data;
    }

    public async SaveMaterialRequisitionResourceItemsForDispatchProject(projectDispatchCode: string | null, requisitionId: string | null, resourceItems: Array<MaterialRequisitionItemModel | null> | null, requestConfig?: HttpClientRequestConfig): Promise<MaterialRequisitionItemModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/materialrequisitions/{requisitionId}/items",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode, requisitionId: (requisitionId === null || requisitionId === undefined) ? undefined : requisitionId },
            null
        );

        const data: MaterialRequisitionItemModel | null = await this.post<Array<MaterialRequisitionItemModel | null> | null, MaterialRequisitionItemModel | null>(uri, resourceItems, requestConfig);
        this.transformModels(data, "Company.MaterialRequisition.MaterialRequisitionItemModel");
        return data;
    }
}
