import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import routerHelper from "helpers/routerHelper";
import additionalFieldsService from "services/additionalFieldsService";
import { AdditionalFieldsSourceType } from "enums/additional-fields-source-type";

@autoinject
export class ProjectMaterialRequisitionAdditionalFields {

    public actions: any = {};
    public dispatchProjectCode: string = "";
    public requisitionId: string = "";
    public readonly: boolean = false;
    public additionalFieldsSource: number = AdditionalFieldsSourceType.Requisition;
    public saveAdditionalFieldsButton: boolean = true;

    constructor(private readonly i18n: I18N) {
    }

    public activate(params: any): any {
        this.bindViewModel(params.dispatchProjectCode, params.requisitionId, params.q);
        this.bindWidget();
    }

    public bindViewModel(dispatchProjectCode: string, requisitionId: string, queryString: string): void {
        const params = routerHelper.getQuerystring(queryString);
        this.readonly = !(params && params.readonly === "false");

        this.dispatchProjectCode = dispatchProjectCode;
        this.requisitionId = requisitionId;
    }

    public bindWidget(): void {
        this.actions = {
            getAll: additionalFieldsService.getAllForRequisitionEquipment.bind(this, this.requisitionId),
            setAll: additionalFieldsService.setAllForRequisitionEquipment.bind(this, this.requisitionId)
        };
    }
}
