define([
    "helpers/apiHelper",
    "services/requests"
], function (apiHelper, requests) {
    "use strict";

    var exports = {
        delMaterialRequisition: function (requisitionId) {
            return requests.remove(apiHelper.getBaseUrl() + "/materialrequisitions/" + requisitionId);
        },

        delMaterialRequisitionItem: function (requisitionId, lineNo) {
            return requests.remove(apiHelper.getBaseUrl() + "/materialrequisitions/" + requisitionId + "/" + lineNo);
        },

        getMaterialRequisition: function (requisitionId) {
            return requests.getJson(apiHelper.getBaseUrl() + "/materialrequisitions/" + requisitionId);
        },

        getMaterialRequisitionItem: function (requisitionId, lineNo) {
            return requests.getJson(apiHelper.getBaseUrl() + "/materialrequisitions/" + requisitionId + "/" + lineNo);
        },

        getMaterialRequisitionsForService: function (dispatchId, fromHistory, onlyActive) {
            var url = apiHelper.getBaseUrl() + "/dispatch/" + dispatchId + "/materialrequisitions"

            if (typeof fromHistory !== 'undefined' && fromHistory !== null) {
                url += "?fromHistory=" + fromHistory;               
            }

            if (typeof onlyActive !== 'undefined' && onlyActive !== null) {
                url += (typeof fromHistory !== 'undefined' && fromHistory !== null ? "&" : "?") + "activeOnly=" + onlyActive;
            }

            return requests.getJson(url);
        },

        getMaterialRequisitionsForProject: function (dispatchProjectCode, onlyActive) {
            var url = apiHelper.getBaseUrl() + "/dispatch/projects/" + dispatchProjectCode + "/materialrequisitions";
            if (typeof onlyActive !== 'undefined' && onlyActive !== null) {
                url += "?activeOnly=" + onlyActive;
            }

            return requests
                .getJson(url);
        },

        getEquipmentRequisitionsForProject: function (dispatchProjectCode, onlyActive) {
            var url = apiHelper.getBaseUrl() + "/dispatch/projects/" + dispatchProjectCode + "/materialrequisitions";
            if (typeof onlyActive !== 'undefined' && onlyActive !== null) {
                url += "?activeOnly=" + onlyActive + "&forProjectEquipment=true";
            } else {
                url += "?forProjectEquipment=true";
            }

            return requests
                .getJson(url);
        },

        setEquipmentRequisitionHeaderForProject: function (dispatchProjectCode, requisition) {
            requisition.Pictures = [];
            return requests.post(apiHelper.getBaseUrl() + "/dispatch/projects/" + dispatchProjectCode + "/materialrequisitions?forProjectEquipment=true",
                requisition);
        },

        setMaterialRequisitionHeaderForService: function (dispatchId, requisition) {
            requisition.Pictures = [];
            return requests.post(apiHelper.getBaseUrl() + "/dispatch/" + dispatchId + "/materialrequisitions", requisition);
        },

        setMaterialRequisitionHeaderForProject: function (dispatchProjectCode, requisition) {
            requisition.Pictures = [];
            return requests.post(apiHelper.getBaseUrl() + "/dispatch/projects/" + dispatchProjectCode + "/materialrequisitions",
                requisition);
        },

        setMaterialRequisitionItemForProject: function (dispatchProjectCode, requisitionId, lineNo, item) {
            return requests.post(apiHelper.getBaseUrl() + "/dispatch/projects/" + dispatchProjectCode + "/materialrequisitions/" + requisitionId + "/" + lineNo, item);
        },

        setMaterialRequisitionItemForService: function (dispatchId, requisitionId, lineNo, item) {
            return requests.post(apiHelper.getBaseUrl() + "/dispatch/" + dispatchId + "/materialrequisitions/" + requisitionId + "/" + lineNo, item);
        },

        getMaterialRequisitionsForFieldService: function (workOrderId, onlyActive) {
            var url = apiHelper.getWorkOrderBaseUrl(workOrderId) + "/materialrequisitions";
            if (typeof onlyActive !== 'undefined' && onlyActive !== null) {
                url += "?activeOnly=" + onlyActive;
            }
            return requests.getJson(url);
        },

        setMaterialRequisitionHeaderForFieldService: function (workOrderId, requisition) {
            requisition.Pictures = [];
            return requests.post(apiHelper.getFieldServiceWorkOrderBaseUrl(workOrderId) + "/materialrequisitions", requisition);
        },

        setMaterialRequisitionItemForFieldService: function (workOrderId, requisitionId, lineNo, item) {
            return requests.post(apiHelper.getFieldServiceWorkOrderBaseUrl(workOrderId) + "/materialrequisitions/" + requisitionId + "/" + lineNo, item);
        },

        setMaterialRequisitionMemo: function (requisition) {
            requisition.Pictures = [];
            return requests.post(apiHelper.getBaseUrl() + "/materialrequisitions/memo", requisition);
        },

        completeRequisition: function (requisitionId) {
            return requests.post(apiHelper.getBaseUrl() + "/materialrequisitions/" + requisitionId + "/complete");
        },
    };

    return exports;
});
