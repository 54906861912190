import { autoinject } from "aurelia-framework";
import queryStringHelper from "helpers/queryStringHelper";
import materialRequisitionService from "services/materialRequisitionService";
import { DispatchProjectService } from "services/dispatch-project-service";
import notificationHelper from "helpers/notificationHelper";
import { I18N } from "aurelia-i18n";

@autoinject()
export class ProjectEquipmentRequisition {

    public dispatchId: any;
    public isProjectResponsible: boolean = false;
    public readonly: boolean = false;
    public fromHistory: boolean = false;
    public actions: any;
    public serviceType: any;
    public dispatchProjectCode: string = "";
    public projectCode: string = "";

    constructor(private readonly i18n: I18N, private readonly dispatchProjectService: DispatchProjectService) {
    }

    public async canActivate(params: any): Promise<any> {
        this.dispatchProjectCode = params.dispatchProjectCode;
        try {
            await this.checkIsProjectActive();
        } catch (e) {
            if (e && e.response && e.response.data && e.response.data.Exception && e.response.data.Exception.ErrorId) {
                notificationHelper.showError(this.i18n.tr(`ApiError.${e.response.data.Exception.ErrorId}`), undefined, { timeOut: 0, extendedTimeOut: 0 });
            } else {
                notificationHelper.showError(e.response.data.Exception.Message, undefined, { timeOut: 0, extendedTimeOut: 0 });
            }
            return false;
        }
    }

    public async checkIsProjectActive(): Promise<void> {
        await this.dispatchProjectService.GetProjectFromDispatchProjectCode(this.dispatchProjectCode);
    }

    public activate(params: any): any {
        this.bindViewModel(params.dispatchProjectCode, params.q);
        this.bindWidget();
    }

    public bindWidget(): void {
        this.actions = {
            getRequisitions: materialRequisitionService.getEquipmentRequisitionsForProject.bind(this, this.dispatchProjectCode)
        };
    }

    public bindViewModel(dispatchProjectCode: string, queryString: string): void {
        this.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(queryString);

        this.dispatchProjectCode = dispatchProjectCode;
    }
}
